// const OWNER = 'OWNER'
// const SELLER = 'SELLER'
// const OWNER_SELLER = 'OWNER/SELLER'
const BALANCE_HOLDER = 'BALANCE HOLDER'
const SUPPLIER = 'SUPPLIER'

// const OWNER_DESC = 'Власник'
// const SELLER_DESC = 'Продавець'
// const OWNER_SELLER_DESC = 'Власник/Продавець'
const BALANCE_HOLDER_DESC = 'Орендодавець'
const SUPPLIER_DESC = 'Постачальник'

export const PARTNERSHIP_TYPES = Object.freeze([
  /*{
    title: OWNER,
    desc: OWNER_DESC,
    type: 1,
  },
  {
    title: SELLER,
    desc: SELLER_DESC,
    type: 2,
  },
  {
    title: OWNER_SELLER,
    desc: OWNER_SELLER_DESC,
    type: 3,
  },*/
  {
    title: BALANCE_HOLDER,
    desc: BALANCE_HOLDER_DESC,
    type: 4,
  },
  {
    title: SUPPLIER,
    desc: SUPPLIER_DESC,
    type: 5,
  },
])

import { PARTNERSHIP_TYPES } from '@/const/partnership-types.enum'

export default {
  filters: {
    getPartnershipTitle(val) {
      const currentPartnership = PARTNERSHIP_TYPES.filter((partnership) => partnership.type === val)
      if (currentPartnership.length) {
        return currentPartnership[0].title
      } else return val
    },

    getPartnershipDesc(val) {
      const currentPartnership = PARTNERSHIP_TYPES.filter((partnership) => partnership.type === val)
      if (currentPartnership.length) {
        return currentPartnership[0].desc
      } else return val
    },
  },
}

<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="partnersList"
        :search="search"
        :loading="loading"
        :options.sync="options"
        :server-items-length="getPartnersTotalCount"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar color="indigo lighten-5" flat>
            <BackButton />
            <v-toolbar-title>Партнери</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              class="shrink mr-10"
              append-icon="mdi-magnify"
              label="Пошук"
              single-line
              hide-details
            ></v-text-field>
            <v-dialog v-model="dialog" max-width="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" small v-bind="attrs" v-on="on"> Додати партнера </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col v-if="editedIndex === -1" cols="12" sm="6" md="4">
                        <v-select
                          v-model.number="editedItem.company_type"
                          :items="PARTNERSHIP_TYPES"
                          item-text="desc"
                          item-value="type"
                          label="Тип"
                          @input="$v.editedItem.company_type.$touch()"
                          @blur="$v.editedItem.company_type.$touch()"
                          :error-messages="typeErrors"
                        >
                          <template v-slot:selection="{ item }">{{ item.desc }}</template>
                          <template v-slot:item="{ item }">{{ item.desc }}</template>
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Назва"
                          @input="$v.editedItem.name.$touch()"
                          @blur="$v.editedItem.name.$touch()"
                          :error-messages="nameErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <AddressAutocomplete
                          v-if="isComponentRerendered"
                          ref="addressAutocomplete"
                          @updateItemsList="updateItemsList"
                          :entityId="clickedPartnerId"
                          :addressId="clickedAddressId"
                          :is-data-saved="isDataSaved"
                          type="partner"
                          :key="addressAutocompleteKey"
                        ></AddressAutocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="save">Так</v-btn>
                  <v-btn text @click="close">Нi</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">Партнера буде видалено</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="deleteItemConfirm">Так</v-btn>
                  <v-btn text @click="closeDelete">Нi</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.id="{ item }">
          <router-link
            :to="{ name: 'PartnerCard', params: { id: currentCompanyId, partnerId: item.id } }"
            class="text-decoration-none"
          >
            <v-tooltip bottom :open-on-focus="false">
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-if="item.status !== null && item.status !== 1"
                  :style="{ color: getListStatusColor(item.status) }"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.id }}
                </span>
                <span v-else v-bind="attrs" v-on="on">{{ item.id }}</span>
              </template>
              <span>{{ getListStatusTitle(item.status) }}</span>
            </v-tooltip>
          </router-link>
        </template>
        <template v-slot:item.name="{ item }">
          <router-link
            :to="{ name: 'PartnerCard', params: { id: currentCompanyId, partnerId: item.id } }"
            class="text-decoration-none"
          >
            {{ item.name }}
          </router-link>
        </template>
        <template v-slot:item.company_type="{ item }">
          {{ item.company_type | getPartnershipDesc }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" @click="editItem(item)" v-bind="attrs" v-on="on"> mdi-pencil-outline </v-icon>
            </template>
            <span>Редагувати</span>
          </v-tooltip>
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" @click="deleteItem(item)" v-bind="attrs" v-on="on"> mdi-delete-outline </v-icon>
            </template>
            <span>Видалити</span>
          </v-tooltip>
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small @click="navigateToPartnerCard(item)" v-bind="attrs" v-on="on"> mdi-eye-outline </v-icon>
            </template>
            <span>Переглянути</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="resetData"> Оновити </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { PARTNERS_SCHEMA } from '@/const/apiSchemas'
import { ROUTER_LINKS } from '@/const/router-links.enum'
import { mergeObjects } from '@/helpers/mergeObjects'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import convertDate from '@/mixins/convertDate'
import resetTableData from '@/mixins/resetTableData'
import convertPartnershipTypes from '@/mixins/convertPartnershipTypes'
import cloneDeep from '@/mixins/cloneDeep'
import { PARTNERSHIP_TYPES } from '@/const/partnership-types.enum'
import ConvertStatusesTypes from '@/mixins/convertStatusesTypes'
import ForceRerender from '@/mixins/forceRerender'
import UpdateItemsList from '@/mixins/updateItemsList'
import BackButton from '@/components/common/BackButton.vue'
import sortUtils from '@/mixins/sortUtils'

export default {
  name: 'PartnersList',
  components: {
    BackButton,
    AddressAutocomplete: () => import('@/components/partials/AddressAutocomplete'),
  },

  mixins: [
    validationMixin,
    convertPartnershipTypes,
    convertDate,
    resetTableData,
    cloneDeep,
    ConvertStatusesTypes,
    ForceRerender,
    UpdateItemsList,
    sortUtils,
  ],

  validations: {
    editedItem: {
      company_type: { required },
      name: { required },
      /*addresses: {
        $each: {
          legal: { required },
        },
      },*/
    },
  },

  data: () => ({
    ROUTER_LINKS: ROUTER_LINKS,
    PARTNERSHIP_TYPES: PARTNERSHIP_TYPES,
    dialog: false,
    dialogDelete: false,
    search: '',
    loading: false,
    clickedPartnerId: null,
    clickedAddressId: null,
    headers: [
      { text: 'ID', align: 'start', sortable: true, value: 'id' },
      { text: 'Назва', value: 'name', sortable: true },
      { text: 'Телефон', value: 'phone', sortable: true },
      { text: 'Тип', value: 'company_type', sortable: true },
      {
        text: '',
        value: 'actions',
        sortable: false,
        width: '125px',
        align: 'center',
      },
    ],
    partnersList: [],
    partner: {},
    producerList: ['Vending.Land', 'Runolfsson, Hartmann and Schroeder'],
    editedIndex: -1,
    isDataSaved: false,
    sortBy: '',
    sortDesc: false,
    options: {},
    // editedItem: { ...PARTNERS_SCHEMA },
    // defaultItem: { ...PARTNERS_SCHEMA },
    editedItem: {},
    defaultItem: {},
    addressAutocompleteKey: 0,
  }),

  computed: {
    ...mapGetters('partners', ['getPartnersTotalCount']),
    ...mapState('partners', ['partners']),

    formTitle() {
      return this.editedIndex === -1 ? 'Створити партнера' : 'Редагувати партнера'
    },
    currentCompanyId() {
      return this.$route.params.id
    },
    typeErrors() {
      const errors = []
      if (!this.$v.editedItem.company_type.$dirty) return errors
      !this.$v.editedItem.company_type.required && errors.push('Це поле обов"язкове')
      return errors
    },
    nameErrors() {
      const errors = []
      if (!this.$v.editedItem.name.$dirty) return errors
      !this.$v.editedItem.name.required && errors.push('Це поле обов"язкове')
      return errors
    },
    /*addressErrors() {
      const errors = []
      this.editedItem.addresses.forEach((item, index) => {
        if (!this.$v.editedItem.addresses.$each[index.toString()].legal.$dirty) {
          return errors
        }
        if (!this.$v.editedItem.addresses.$each[index.toString()].legal.required) {
          errors[index] = 'Це поле обов"язкове'
        }
      })
      return errors
    },*/
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    options: {
      handler() {
        this.paginateTo()
      },
      deep: true,
    },
  },

  mounted() {
    this.clone()
  },

  methods: {
    ...mapActions('partners', ['loadPartners', 'createNewPartner', 'updateSelectedPartner', 'deleteSelectedPartner']),
    ...mapActions('addresses', ['deleteAddress']),
    ...mapActions('logs', ['displayWarningAlert']),

    clone() {
      this.editedItem = this.cloneObjectDeep(PARTNERS_SCHEMA)
      this.defaultItem = this.cloneObjectDeep(PARTNERS_SCHEMA)
    },

    async initialize() {
      this.partnersList = [...this.partners]
    },

    async paginateTo() {
      if (this.currentCompanyId !== 'undefined') {
        this.loading = true
        const payload = {
          companyId: this.currentCompanyId,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sort: this.sortObject,
        }
        await this.loadPartners(payload)
        await this.initialize()
        this.loading = false
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },

    editItem(item) {
      // TODO: refresh deep object (when exit editing)
      // this.paginateTo()
      this.partner = { ...item }
      this.editedIndex = this.partnersList.indexOf(item)
      this.editedItem = mergeObjects(this.editedItem, item)
      this.clickedPartnerId = item.id
      this.clickedAddressId = item.address?.id
      // this.convertManufactureDate()
      this.forceRerender()
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.partnersList.indexOf(item)
      // this.editedItem = Object.assign({}, item)
      this.clickedPartnerId = item.id
      this.clickedAddressId = item.address?.id
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.loading = true
      const payload = {
        companyId: this.currentCompanyId,
        partnerId: this.clickedPartnerId,
      }
      const deleteAddressPayload = {
        type: 'partner',
        companyId: this.currentCompanyId,
        partnerId: this.clickedPartnerId,
        addressId: this.clickedAddressId,
      }
      await Promise.all([this.deleteSelectedPartner(payload), this.deleteAddress(deleteAddressPayload)])
      this.closeDelete()
      await this.paginateTo()
      this.loading = false
    },

    close() {
      this.dialog = false
      this.$v.editedItem.$reset()
      this.addressAutocompleteKey++
      this.$nextTick(() => {
        this.editedItem = this.cloneObjectDeep(this.defaultItem)
        // this.convertedManufactureDate = ''
        this.editedIndex = -1
        this.clickedPartnerId = null
        this.clickedAddressId = null
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.clickedPartnerId = null
        this.clickedAddressId = null
      })
    },

    async save() {
      this.$refs.addressAutocomplete.handleButtonClicked()
      this.$v.editedItem.$touch()
      if (
        this.$v.$anyError ||
        this.$refs.addressAutocomplete.isAddressEmpty ||
        !this.$refs.addressAutocomplete.editedItem.building_number
      )
        return
      this.loading = true
      // this.editedItem.manufacture_date = this.convertedManufactureDate
      if (this.editedIndex > -1) {
        const payload = {
          companyId: this.currentCompanyId,
          partnerId: this.clickedPartnerId,
          updatedPartner: this.editedItem,
        }
        await this.updateSelectedPartner(payload)
      } else {
        const payload = {
          companyId: this.currentCompanyId,
          newPartner: {
            ...this.editedItem,
            contacts: null,
          },
        }
        await this.createNewPartner(payload)
      }
      this.isDataSaved = true
    },

    navigateToPartnerCard(item) {
      const clickedItemId = item.id
      this.$router.push({
        name: 'PartnerCard',
        params: { id: this.currentCompanyId.toString(), partnerId: clickedItemId.toString() },
      })
    },

    convertManufactureDate() {
      // const date = new Date(this.editedItem.manufacture_date)
      // this.convertedManufactureDate = date.toISOString().split('T')[0]
      this.convertedManufactureDate = this.editedItem.manufacture_date.split('T')[0]
    },

    addAddressField() {
      const newItem = this.cloneObjectDeep(this.defaultItem.addresses[0])
      this.editedItem.addresses.push(newItem)
    },

    removeAddressField(index) {
      this.editedItem.addresses.splice(index, 1)
    },
  },
}
</script>

<style scoped></style>
